import mixpanel from "mixpanel-browser";
import React from "react";
import { BsStarFill } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa6";
const Hero = () => {
  return (
    <div className="bg-white pt-24">
      <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <div className="px-3 my-5 py-2 flex justify-between items-center rounded-full bg-[#ECFDF3] border-[#ABEFC6] gap-3">
            {/* <div className="bg-[#159AA8] py-1 px-2 rounded-xl">
              <h3 className="font-[400] leading-5 text-[14px] text-white ">
                NEW
              </h3>
            </div>
            <h4 className="font-[500] leading-5 text-[14px] text-[#067647]">
              Our 2023 Annual Report is here! Download your copy
            </h4>
            <div className=" py-1 px-2 cursor-pointer hover:opacity-70 transition duration-300 ease-in-out">
              <a
                href={"https://docsend.com/view/w27seqhmzqj2bx3j"}
                download={`Annual Report Documents`}
                target="_blank"
              >
                <FaChevronRight color="#067647" />
              </a>
            </div> */}
          </div>
          <h1 className="mb-4 text-[#101828] text-[40px] lg:text-[60px] font-bold leading-tight">
            Global Ethical Investments for Africa.
          </h1>

          <p className=" text-[#475467] text-base lg:text-xl tracking-loose mx-auto lg:mx-0  w-[80%]">
            Seize opportunities, maximize returns, invest smartly without
            compromising your values or faith
          </p>
          <button
            onClick={() => {
              mixpanel.track("Get-Started-Clicked");
              window.location = "https://app.halvestco.com/register";
            }}
            className="rounded-[8px] text-sm w-[211px] h-[52px] flex justify-center items-center mx-auto lg:mx-0 bg-[#159AA8] text-[#fff] font-bold mt-16 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            Get Started
          </button>
          {/* <div className="my-8 flex gap-4s items-center mx-auto sm:mx-0">
            <div className="flex ">
              <img
                src={require("../Assets/Images/Avatar-1.png")}
                className="w-10 h-10 "
              />
              <img
                src={require("../Assets/Images/Avatar2.png")}
                className="w-10 h-10 -ml-3"
              />
              <img
                src={require("../Assets/Images/Avatar-2.png")}
                className="w-10 h-10 -ml-3"
              />
              <img
                src={require("../Assets/Images/Avatar-3.png")}
                className="w-10 h-10 -ml-3"
              />
              <img
                src={require("../Assets/Images/Avatar-4.png")}
                className="w-10 h-10 -ml-3"
              />
            </div>
            <div className="">
              <div className="flex gap-2 ">
                <div className="flex gap-1 items-center">
                  <BsStarFill size={"20px"} color="#FEC84B" />

                  <BsStarFill size={"20px"} color="#FEC84B" />
                  <BsStarFill size={"20px"} color="#FEC84B" />
                  <BsStarFill size={"20px"} color="#FEC84B" />
                  <BsStarFill size={"20px"} color="#FEC84B" />
                </div>
                <h5 className="text-[#344054] font-[500] text-base">5.0</h5>
              </div>
              <h5 className="text-[#475467] font-[500] text-base">
                from 200+ reviews
              </h5>
            </div>
          </div> */}
        </div>

        <div className="w-full md:w-3/5 py-6 text-center relative">
          <img
            src={require("../Assets/Images/Summary Card.png")}
            className="w-[199px] h-[142px] absolute right-0 lg:right-[20%] z-10 top-[-8%]"
          />
          <img
            className="w-full md:w-4/5 z-50"
            src={require("../Assets/hero.png")}
          />
          <img
            src={require("../Assets/Images/Card.png")}
            className="w-[156x] h-[116px] lg:w-[206px] object-cover lg:h-[166px] absolute bottom-[5%] left-[-1%]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
